export const enContent = (
  <>
    <header>
      <h2>Preamble</h2>
      <p>
        Dear Lincy Users: Guangzhou Xuesheng Technology Co., Ltd. has updated
        the Privacy Policy. Please carefully read this Privacy Policy and make
        sure you understand Lincy's rules for processing user personal
        information. If you have any questions during the reading process, you
        can contact Lincy customer service for consultation. If you do not agree
        to any of the terms in the Privacy Policy, you should immediately stop
        using or accessing Lincy related services and websites.
      </p>
      <p>
        Last Updated: <time dateTime="2024-04-03">April 3, 2024</time>
        <br />
        Effective Date: <time dateTime="2021-12-16">December 16, 2021</time>
      </p>
    </header>
    <article>
      <h1>Privacy Policy</h1>
      <p>
        The security of your personal information is of paramount importance to
        Guangzhou Xuesheng Technology Co., Ltd. (hereinafter referred to as "we"
        or "us"). We will take appropriate measures in accordance with legal and
        regulatory requirements to protect the security and controllability of
        your personal information. For the above purposes, we have formulated
        this Privacy Policy and make the following reminders to you:
      </p>
      <p>
        When you use our products or services, we may collect and use your
        relevant information. We hope to explain to you through this Privacy
        Policy how we collect, use, store, share, and transfer this information
        when you use our products or services, as well as the ways we provide
        you to access, update, delete, and protect this information.
      </p>
      <p>
        This Privacy Policy is closely related to the Lincy related services you
        use and the various business functions included in the service
        (hereinafter collectively referred to as "our Products or Services"). We
        hope that you will carefully read and confirm that you have fully
        understood the contents of this Privacy Policy before using our Products
        or Services, and that you can make choices that you deem appropriate
        according to the guidelines of this Privacy Policy. Your use of or
        continued use of our Products or Services after we update this Privacy
        Policy (we will promptly notify you of the update) means that you agree
        to the content of this Privacy Policy (including the updated version)
        and agree to our collection, use, storage, and sharing of your relevant
        information in accordance with this Privacy Policy.
      </p>
      <p>
        We promise to strictly keep your personal information and other data
        confidential and process your personal information in strict accordance
        with the contents set forth in this Privacy Policy. We will collect,
        use, store, share, and transfer your personal information based on your
        consent and other legal basis for processing your personal information.
      </p>
      <h2>I. Definitions</h2>
      <p>
        1.1 Personal Information: Personal information refers to any information
        recorded electronically or otherwise that can be used to identify a
        specific natural person or reflect the activities of a specific natural
        person, either alone or in combination with other information, including
        but not limited to the natural person's name, date of birth, ID number,
        personal biometric information, address, and phone number.
      </p>
      <p>
        1.2 Sensitive Personal Information: Sensitive personal information
        refers to personal information that, once leaked, illegally provided, or
        abused, may endanger personal and property safety, and is highly likely
        to cause damage to personal reputation, physical and mental health, or
        discriminatory treatment. For example, sensitive personal information
        includes home address, personal biometric information, bank account
        numbers, communication content, and health and physiological content.
      </p>
      <h2>II. Legal Basis for Our Processing of Personal Information</h2>
      <p>
        If you are a user in mainland China, we will collect and use your
        personal information in accordance with the Personal Information
        Protection Law of the People's Republic of China, the Cybersecurity Law
        of the People's Republic of China, the Information Security Technology -
        Personal Information Security Specification (GB/T 35273-2020), and other
        relevant laws and regulations to provide you with Lincy related products
        and services. We will generally only collect your personal information
        with your consent, or may need personal information to protect your
        vital interests or the interests of others.
      </p>
      <h2>III. How We Use Your Personal Information</h2>
      <p>
        3.1 We will only collect and use your personal information based on the
        principles of legality, legitimacy, necessity, and for the purpose of
        realizing the functions of our products and services.
      </p>
      <p>
        3.2 If we use the information for purposes not specified in this policy
        or use information collected for a specific purpose for other purposes,
        we will notify you in a reasonable manner and obtain your consent in
        advance.
      </p>
      <p>
        3.3 We collect your information to provide you with a better course
        experience. Some of our services may require you to provide specific
        sensitive personal information to implement specific functions. If you
        choose not to provide such information, you may not be able to use
        certain functions in the service normally, but it will not affect your
        use of other functions in the service. If you actively provide your
        sensitive personal information, it means that you agree to our
        processing of your sensitive personal information for the purposes and
        in the manner described in this policy. To facilitate you in obtaining
        the services you want, we will provide a switch setting for
        "Personalized Recommendations". If you do not want us to recommend users
        and dynamic content that meet your learning needs and progress, you can
        turn this switch off in the settings.
      </p>
      <p>
        3.4 When you use our Products or Services, you need to log in through a
        third-party associated account. We will request the third party to
        provide your account nickname, avatar, account ID, gender, and other
        information. We also need you to provide your mobile phone number to
        bind your learning qualifications. If you refuse to provide the above
        information, you will not be able to use our Products or Services.
      </p>
      <p>
        3.5 When you participate in our activities, fill out user
        questionnaires, and ask questions online with teaching assistants, we
        may collect information such as your name, phone number, address,
        educational background, occupation, and years of work experience. We
        will receive and record information related to your browser, computer,
        and mobile device, including:
      </p>
      <li>
        Device information such as device model, operating system version,
        device settings, unique device identifier, and other software and
        hardware characteristic information;
      </li>
      <li>
        Location information obtained through GPS, Bluetooth or WIFI signal
        access points, Bluetooth, and base station sensor information;
      </li>
      <li>
        When you use the products or services provided by our website or client,
        we will automatically collect detailed information about your use of our
        products or services and save it as relevant network logs.
      </li>
      <p>
        3.6 When you purchase a course and make a payment, we will collect your
        third-party payment account (such as Alipay account, WeChat account,
        ApplePay account, or other forms of bank card information). If you have
        enabled fingerprint payment or facial recognition payment, you need to
        use your fingerprint or facial information for verification. We will not
        collect your original fingerprint or facial image. Your original
        fingerprint image is only stored on the device you authorized to collect
        fingerprints.
      </p>
      <p>
        3.7 Account Cancellation Method: You can initiate an account
        cancellation application in Settings - About - Delete Account. We will
        delete your personal information according to your request after
        receiving your cancellation application.
      </p>
      <p>3.8 Third-Party SDK Information Collection</p>
      <ol>
        <li>
          WeChat Open SDK
          <ul>
            <li>Purpose of Use: To help users log in using WeChat account</li>
            <li>
              Usage Scenario: When users choose to log in with WeChat account
            </li>
            <li>
              Sharing Method: SDK collects locally, no data sharing involved
            </li>
            <li>
              Third-Party Name: Shenzhen Tencent Computer System Co., Ltd.
            </li>
            <li>
              Information Collected/Shared: Network access, obtaining Wi-Fi
              status, reading phone status, writing to external storage, using
              storage permissions to read external storage information, device
              identification information (including IMEI, device serial number,
              Android ID, MAC address, SIM card serial number), installed APP
              information, sensor information, clipboard.
            </li>
            <li>
              Information Type: Operating system information, device model
              information, application list information
            </li>
            <li>
              Privacy
              Policy: https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8
            </li>
          </ul>
        </li>
        <li>
          Alibaba Cloud Mobile Push SDK
          <ul>
            <li>
              Usage Scenario: Used to identify push devices and implement push
              function to individual devices
            </li>
            <li>Third-Party Name: Alibaba Cloud Computing Co., Ltd.</li>
            <li>
              Information Collected: Device information (including operating
              system, device model), IP, operator information
            </li>
            <li>
              Privacy
              Policy: https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112071754_83380.html
            </li>
          </ul>
        </li>
        <li>
          Flash Verification SDK
          <ul>
            <li>
              Third-Party Entity: Shanghai Jingmeng Information Technology Co.,
              Ltd. SDK
            </li>
            <li>
              Purpose: To provide APP users with one-click login, number
              verification, and security verification services SDK
            </li>
            <li>
              Information Processing Type: In order to realize the gateway
              number fetching technology, Flash Verification SDK needs to obtain
              the local number, device identifier (including IDFV, OAID), device
              hardware information (including device model, device hardware
              manufacturer), operating system information (including operating
              system version), network information (including network type,
              operator name, IP address, network status, network type), SIM card
              information (including SIM card status, subId), and application
              information (including BundleId, package name, package signature).
            </li>
            <li>
              Data Processing Method: De-identification, encrypted transmission,
              and other security methods
            </li>
            <li>
              Privacy
              Policy: https://doc.chuanglan.com/document/T98GE1KYCZDMJDHN
            </li>
          </ul>
        </li>
        <li>
          China Mobile
          <ul>
            <li>
              Information Collected: Network type, network address, operator
              type, local number, SIM card status, mobile device type, mobile
              operating system, hardware manufacturer
            </li>
            <li>
              Purpose and Usage: Identify the user's mobile phone number for
              quick login/number verification, problem inquiry, analysis, and
              risk control
            </li>
            <li>Information Processing Method: SDK collects on its own</li>
            <li>Third-Party Entity: China Mobile Internet Co., Ltd.</li>
            <li>Agreement Name: China Mobile Authentication Service Terms</li>
            <li>
              Agreement
              Link: https://wap.cmpassport.com/resources/html/contract.html
            </li>
          </ul>
        </li>
        <li>
          China Unicom
          <ul>
            <li>
              Information Collected: Network type, network address, operator
              type, local mobile phone number, mobile device type, mobile
              operating system, hardware manufacturer
            </li>
            <li>
              Purpose and Usage: Identify the user's mobile phone number for
              quick login/number verification
            </li>
            <li>Information Processing Method: SDK collects on its own</li>
            <li>
              Third-Party Entity: China United Network Communications Co., Ltd.
            </li>
            <li>
              Agreement Name: China Unicom Service and Privacy Agreement Terms
            </li>
            <li>
              Agreement Link: https://ms.zzx9.cn/html/oauth/protocol2.html
            </li>
          </ul>
        </li>
        <li>
          China Telecom
          <ul>
            <li>
              Information Collected: Registered mobile phone number, local
              number, network connection type, network status information,
              network address, operator type, mobile device type, mobile device
              manufacturer, mobile operating system type and version
            </li>
            <li>
              Purpose and Usage: Identify the user's mobile phone number for
              quick login/number verification and business risk control
            </li>
            <li>Information Processing Method: SDK collects on its own</li>
            <li>
              Third-Party Entity: Tianyi Digital Life Technology Co., Ltd.
            </li>
            <li>Agreement Name: Tianyi Service and Privacy Agreement</li>
            <li>
              Agreement
              Link: https://e.189.cn/sdk/agreement/detail.do?hidetop=true
            </li>
          </ul>
        </li>
        <li>
          iFlytek SDK
          <ul>
            <li>Usage Scenario: Used for speech synthesis</li>
            <li>Third-Party Name: iFLYTEK Co., Ltd.</li>
            <li>
              Privacy
              Policy: https://www.xfyun.cn/doc/tts/online_tts/online_tts_sdkprivacy.html
            </li>
          </ul>
        </li>
        <li>
          Xiaomi Push SDK
          <ul>
            <li>Developer Name: Beijing Xiaomi Mobile Software Co., Ltd.</li>
            <li>Purpose of Use: Used to push messages to you</li>
            <li>
              Types of Personal Information Shared: Device identifiers (OAID,
              encrypted Android ID), push message content, device information
              (device manufacturer, model, location, operator name, etc.),
              notification bar settings
            </li>
            <li>
              Permissions that may be invoked: Access network status, obtain
              device information, write to device storage
            </li>
            <li>
              Privacy Policy
              Link: https://dev.mi.com/console/doc/detail?pId=1822
            </li>
          </ul>
        </li>
        <li>
          OPPO Push SDK
          <ul>
            <li>Third-Party Name: Guangdong Huantai Technology Co., Ltd.</li>
            <li>Purpose of Use: Used to push messages on OPPO devices</li>
            <li>
              Processing Method: Secure processing methods such as
              de-identification, encrypted transmission and processing
            </li>
            <li>
              Types of Personal Information Collected: Device identification
              information, network information, device status information,
              application usage information
            </li>
            <li>
              Privacy Policy
              Link: https://open.oppomobile.com/new/developmentDoc/info?id=10288
            </li>
          </ul>
        </li>
        <li>
          Huawei Push SDK
          <ul>
            <li>Third-Party Name: Huawei Software Technology Co., Ltd.</li>
            <li>Purpose of Use: Used to push messages on Huawei devices</li>
            <li>
              Processing Method: Secure processing methods such as
              de-identification, encrypted transmission and processing
            </li>
            <li>
              Types of Personal Information Collected: Application
              identification information, message delivery records, etc.
            </li>
            <li>
              Privacy Policy
              Link: https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177
            </li>
          </ul>
        </li>
        <li>
          Meizu Push SDK
          <ul>
            <li>
              Third-Party Name: Zhuhai Xingji Meizu Information Technology Co.,
              Ltd.
            </li>
            <li>Purpose of Use: Used to push messages on Meizu devices</li>
            <li>
              Processing Method: Secure processing methods such as
              de-identification, encrypted transmission and processing
            </li>
            <li>
              Types of Personal Information Collected: Device identification
              information, location information, network status information,
              operator information
            </li>
            <li>Privacy Policy Link: http://open.flyme.cn/docs?id=202</li>
          </ul>
        </li>
        <li>
          VIVO Push SDK
          <ul>
            <li>Third-Party Name: Vivo Mobile Communication Co., Ltd.</li>
            <li>Purpose of Use: Used to push messages on VIVO devices</li>
            <li>
              Processing Method: Secure processing methods such as
              de-identification, encrypted transmission and processing
            </li>
            <li>
              Types of Personal Information Collected: Device identification
              information, network status information
            </li>
            <li>
              Privacy Policy
              Link: https://dev.vivo.com.cn/documentCenter/doc/652
            </li>
          </ul>
        </li>
        <li>
          TikTok Business SDK
          <ul>
            <li>Third-party Name: ByteDance Technology Co., Ltd.</li>
            <li>
              Purpose of Use: Collecting and analyzing anonymized usage data
            </li>
            <li>
              Handling Method: Secure handling methods through
              de-identification, encrypted transmission, and processing
            </li>
            <li>
              Types of Personal Information Collected: Device identifier
              information, network status information, Google Advertising ID
              (GAID)
            </li>
            <li>
              Privacy Policy Link:
              https://ads.tiktok.com/i18n/official/policy/business-products-terms
            </li>
          </ul>
        </li>
      </ol>
      <h2>
        IV. How We Share, Transfer, and Publicly Disclose Your Personal
        Information
      </h2>
      <p>4.1 Sharing</p>
      <p>
        We will not share your personal information with any third-party
        companies, organizations, or individuals, except in the following
        circumstances:
      </p>
      <li>
        With Your Consent: We will share your personal information with other
        parties after obtaining your explicit consent or the consent of your
        parent/legal guardian if you are a minor.
      </li>
      <li>
        Legal Requirements: We may share your personal information with external
        parties in accordance with laws and regulations or mandatory
        requirements of government authorities.
      </li>
      <li>
        Service Provision and Improvement: We may share your information with
        partners and other third parties to provide, maintain, and improve our
        Products or Services. These third parties are obligated to protect your
        information under the terms of a data processing agreement.
      </li>
      <li>
        Protection of Rights and Interests: To the extent permitted by laws and
        regulations, in order to comply with the law, maintain the interests,
        property, or safety of us and our affiliates or partners, you or other
        users, or the public from harm, we may exchange information with other
        companies and organizations.
      </li>
      <li>
        Limited Sharing for Specific Purposes: We will only share your personal
        information for legitimate, justifiable, necessary, specific, and
        explicit purposes, and will only share the minimum amount of personal
        information necessary to provide the services. For companies,
        organizations, and individuals with whom we share personal information,
        we will enter into strict confidentiality agreements, requiring them to
        handle personal information in accordance with our instructions,
        this Privacy Policy, and any other relevant confidentiality and security
        measures. They are prohibited from using the shared personal information
        for any other purpose.
      </li>
      <p>4.2 Transfer</p>
      <p>
        We will not transfer your personal information to any company,
        organization, or individual, except in the following circumstances:
      </p>
      <li>
        With Your Consent: We will transfer your personal information to other
        parties after obtaining your explicit consent or the consent of your
        parent/legal guardian if you are a minor.
      </li>
      <li>
        Business Transactions: In the event of a merger, acquisition, or
        bankruptcy liquidation, if the transfer of personal information is
        involved, we will require the new company or organization holding your
        personal information to continue to be bound by this Privacy Policy.
        Otherwise, we will require the company or organization to re-obtain your
        authorization and consent.
      </li>
      <p>4.3 Public Disclosure</p>
      <li>
        We will only publicly disclose your personal information under the
        following circumstances:
      </li>
      <li>
        With Your Consent: After obtaining your explicit consent or the consent
        of your parent/legal guardian if you are a minor.
      </li>
      <li>
        Legal Disclosure: We may publicly disclose your personal information as
        required by law, legal process, litigation, or mandatory requirements of
        government authorities.
      </li>
      <p>4.4 Exceptional Circumstances</p>
      <li>
        You acknowledge and agree that we may share, transfer, or publicly
        disclose personal information without prior consent in the following
        circumstances:
      </li>
      <li>
        Directly related to national security or national defense security;
      </li>
      <li>
        Directly related to public safety, public health, or major public
        interests;
      </li>
      <li>
        Directly related to criminal investigation, litigation, arbitration, or
        judgment execution;
      </li>
      <li>
        To protect your vital interests or those of other individuals, such as
        life and property, when obtaining your consent is difficult or
        impossible;
      </li>
      <li>
        Personal information that you have disclosed to the public yourself;
      </li>
      <li>
        Personal information collected from legally publicly disclosed
        information, such as legitimate news reports or government information
        disclosure channels.
      </li>
      <h2>V. How We Protect Your Personal Information</h2>
      <p>
        The security of your personal information is of paramount importance to
        us. We will strictly comply with relevant laws and regulations and take
        reasonable and feasible measures recognized by the industry to protect
        your personal information. We strive to prevent unauthorized access,
        disclosure, use, and modification of your information, and to avoid
        damage or loss of information.
      </p>
      <p>5.1 Technical and Data Security Measures</p>
      <p>
        We strive to adopt various physical, electronic, and managerial security
        measures that comply with industry standards to protect the security of
        your personal information. We actively establish data classification and
        grading systems, data security management specifications, and data
        security development specifications to manage and regulate the storage
        and use of personal information, ensuring that we do not collect
        personal information that is not related to the services we provide.
      </p>
      <p>
        We implement comprehensive security controls on data through
        confidentiality agreements with individuals who have access to the
        information, as well as monitoring and auditing mechanisms. This helps
        prevent unauthorized access, disclosure, use, modification, damage, or
        loss of your personal information. We have employed industry-standard
        security measures to safeguard the personal information you provide,
        preventing unauthorized access, disclosure, use, modification, damage,
        or loss of data. We will take all reasonable and feasible measures to
        protect your personal information.
      </p>
      <p>5.2 Security Incident Handling</p>
      <p>
        We will endeavor to ensure the security of any information you send to
        us. However, please understand that due to technological limitations and
        various malicious methods that may exist in the internet industry, it is
        impossible to guarantee 100% security of information at all times. You
        need to understand that the systems and communication networks you use
        to access our services may encounter problems due to factors beyond our
        control.
      </p>
      <p>
        To prevent security incidents, we have formulated sound early warning
        mechanisms and emergency response plans. In the unfortunate event of a
        personal information security incident, we will promptly inform you
        according to legal and regulatory requirements, including:
      </p>
      <li>
        Basic information about the security incident and its potential impact;
      </li>
      <li>Measures we have taken or will take to address the incident;</li>
      <li>Suggestions for you to take to prevent and mitigate risks;</li>
      <li>Remedial measures we will provide to you.</li>
      <p>
        We will immediately activate our emergency response plan to minimize
        losses. We will promptly inform you of the relevant situation of the
        incident through phone calls, push notifications, etc. When it is
        difficult to inform users one by one, we will issue announcements in a
        reasonable and effective manner.
      </p>
      <p>
        At the same time, we will proactively report the handling of personal
        information security incidents to regulatory authorities as required and
        cooperate closely with government agencies' work.
      </p>
      <h2>VI. Your Rights to Manage Your Personal Information</h2>
      <p>
        We will ensure that you can exercise the following rights regarding your
        personal information in accordance with this Privacy Policy:
      </p>
      <p>
        6.1 Access and Correction of Your Personal Information: Except for
        special circumstances stipulated by laws and regulations, you have the
        right to access and correct your personal information.
      </p>
      <p>
        6.2 Deletion of Your Personal Information: You can request us to delete
        your personal information under the following circumstances:
      </p>
      <li>
        If our processing of your personal information violates laws and
        regulations;
      </li>
      <li>
        If we collect or use your personal information without your consent;
      </li>
      <li>
        If our processing of your personal information breaches our agreement
        with you;
      </li>
      <li>
        If you no longer use our Products or Services, or if you have
        re-registered your account;
      </li>
      <li>If we no longer provide Products or Services to you.</li>
      <p>
        After you delete your information from our Services, we may not
        immediately delete the corresponding information in the backup system,
        but we will delete it when the backup is updated.
      </p>
      <p>
        6.3 Responding to Your Requests: To ensure security, you may need to
        provide a written request or prove your identity in other ways. We may
        require you to verify your identity before processing your request. We
        may refuse requests that are groundlessly repetitive, require excessive
        technical means, endanger the legitimate rights and interests of others,
        or are highly impractical. In the following situations, we will not be
        able to respond to your request in accordance with laws and regulations:
      </p>
      <li>
        Directly related to national security or national defense security;
      </li>
      <li>
        Directly related to public safety, public health, or major public
        interests;
      </li>
      <li>
        Directly related to criminal investigation, litigation, arbitration, or
        judgment execution;
      </li>
      <li>
        Where there is sufficient evidence to prove that you have subjective
        malice or abuse of rights;
      </li>
      <li>
        If responding to your request would seriously damage the legitimate
        rights and interests of you or other individuals or organizations;
      </li>
      <li>Involving trade secrets.</li>
      <h2>VII. How We Use Cookies and Similar Technologies</h2>
      <p>
        7.1 Cookies: To ensure the normal operation of the website, we store
        small data files called cookies on your computer or mobile device.
        Cookies typically contain identifiers, site names, and some numbers,
        characters, and data such as learning progress. We will not use cookies
        for any purpose other than the purposes described in this policy. You
        can manage or delete cookies according to your preferences. You can
        clear all cookies stored on your computer, and most web browsers offer
        the function of blocking cookies. However, if you do so, you will need
        to change your user settings every time you visit our website. For more
        information on how to change your browser settings, please visit the
        relevant settings page of your browser.
      </p>
      <p>
        7.2 Web Beacons and Pixel Tags: For example, the emails we send to you
        may contain links to content on our website. If you click on such links,
        we will track the initial click to help us understand your product or
        service better and improve user experience. A web beacon is usually a
        transparent image embedded in a website or email. We use web beacons to
        understand whether you have opened the email. If you do not want your
        activity to be tracked in this way, you can unsubscribe from our mailing
        list at any time.
      </p>
      <h2>VIII. How We Protect the Personal Information of Minors</h2>
      <p>
        If you are a minor under the age of 18, you should obtain the consent of
        your parent/legal guardian before using our Products or Services, and
        read this Privacy Policy under their accompaniment and guidance. If we
        discover that we have collected personal information from a minor under
        the age of 14 without first obtaining the consent of their parent/legal
        guardian, we will delete the relevant data in stages.
      </p>
      <h2>IX. How to Update and Revise this Privacy Policy</h2>
      <p>
        9.1 This Privacy Policy is an important part of the Lincy User Service
        Agreement. Lincy reserves the right to update or modify this Privacy
        Policy from time to time.
      </p>
      <p>
        9.2 We will not reduce your rights under this Privacy Policy without
        your express consent. We will notify you of any changes to this Privacy
        Policy in a reasonable manner, such as through official account push
        notifications and pop-up windows, so that you can stay informed of any
        changes.
      </p>
      <p>
        9.3 If you do not agree to the revised Privacy Policy, you have the
        right and should immediately stop using Lincy's services. If you
        continue to use the services provided by Lincy, it will be deemed that
        you accept the modifications made by Lincy to the relevant terms of
        this Privacy Policy.
      </p>
      <h2>X. How to Contact Us and Dispute Resolution</h2>
      <p>
        10.1 If you have any questions, comments, or suggestions regarding
        this Privacy Policy or related matters, you can contact Lincy at any
        time through the customer service hotline 400-990-9669.
      </p>
      <p>
        10.2 If you believe that our processing of your personal information has
        infringed upon your legitimate rights and interests, you can also report
        it to the relevant government departments.
      </p>
    </article>
  </>
);
