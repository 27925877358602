export const zhHantContent = (
  <>
    <header>
      <h2>前言</h2>
      <p>
        尊敬的語有靈犀用戶：廣州學晟科技有限公司對《隱私協議》進行了更新，請您仔細閱讀本《隱私協議》並確定了解語有靈犀對用戶個人信息的處理規則。閱讀過程中，如您有任何疑問，可聯繫語有靈犀客服進行諮詢；如不同意《隱私協議》中的任何條款，您應當立即停止使用或訪問語有靈犀相關服務及網站。
      </p>
      <p>
        最後更新日期：<time dateTime="2024-04-03">2024年4月3日</time>
        <br />
        生效時間：<time dateTime="2021-12-16">2021年12月16日</time>
      </p>
    </header>
    <article>
      <section>
        <h1>隱私協議</h1>
        <p>
          您的個人信息安全對廣州學晟科技有限公司（以下簡稱“我們”）來説至關重要。我們將按照法律法規的要求，採取相應的措施保護您個人信息的安全可控。基於上述目的，我們製定本《隱私協議》並對您做出如下提示：
        </p>
        <p>
          您在使用我們的産品或服務時，我們可能會集和使用您的相關信息。我們希望通過本隱私協議向您説明我們在您使用我們的産品或服務時如何收集、使用、保存、共享和轉讓這些信息，以及我們爲您提供的訪問、更新、刪除和保護這些信息的方式。
        </p>
        <p>
          本隱私協議與您所使用的語有靈犀相關服務以及該服務所包括的各種業務功能（以下統稱“我們的産品或服務”）息息相關，希望您在使用我們的産品或服務前仔細閱讀並確認您已經充分理解本條款所冩明的內容，並讓您可以按照本隱私協議的指引做出您認爲適當的選擇。您使用或在我們更新本隱私協議後（我們會及時提示您更新的情況）繼續使用我們的産品或服務，即意味着您同意本隱私協議（含更新版本）內容，並且同意我們按照本隱私協議收集、使用、保存和共享您的相關信息。
        </p>
        <p>
          我們承諾會對您的個人信息和其它數據進行嚴格保密，並嚴格按照本隱私協議所闡述的內容處理您的個人信息。我們會根據您的同意和其它可處理您個人信息的法律依據收集、使用、存儲、共享和轉移您的個人信息。
        </p>
        <h2>一、定義</h2>
        <p>
          1.1
          個人信息：個人信息是指以電子或其他方式記錄的能夠單獨或者與其他信息結合識別特定自然人身份或者反映特定自然人活動情況的各種信息，包括但不限於自然人的姓名、出生日期、身份証件號碼、個人生物識別信息、住址、電話號碼等。
        </p>
        <p>
          1.2 個人敏感信息：
          個人敏感信息是指一旦洩露、非法提供或濫用可能危害人身和財産安全，極易導緻個人名譽、身心健康受到損傷或歧視性待遇等的個人信息。例如，個人敏感信息包括家庭住址、個人生物識別信息、銀行賬號、通信內容、健康生理內容等。
        </p>
        <h2>二、我們處理個人信息的法律依據</h2>
        <p>
          如果您是中華人民共和國大陸地區的用戶，我們將依據《中華人民共和國個人信息保護法》、《中華人民共和國網絡安全法》、《信息安全技術個人信息安全規範》（GB
          /
          T35273-2020）以及其相關法律法規收集和使用您的個人信息，爲您提供語有靈犀相關産品及服務。我們通常隻會在徵得您的同意的情況下收集您的個人信息，或者可能需要個人信息來保護您的重要利益或其他人的利益。
        </p>
        <h2>三、我們如何使用您的個人信息</h2>
        <p>
          3.1
          我們僅會考慮實現産品及服務功能的目的，根據合法，正當，必要的原則，收集和使用您的個人信息。
        </p>
        <p>
          3.2
          如果我們將信息用作本政策未載明的其他用途或根據特定目的收集而來的信息用於其他目的時，我們將以合理的方式通知您，並會事先徵求您的同意。
        </p>
        <p>
          3.3
          我們收集您的信息是爲了給您提供更好的課程體驗。我們的部分服務可能需要您提供特定的個人敏感信息來實現特定功能。若您選擇不提供該類信息，則可能無法正常使用服務中的特定功能，但不影響您使用服務中的其他功能。若您主動提供您的個人敏感信息，即表示您同意我們按本政策所述目的和方式來處理您的個人敏感信息。爲了方便您得到想要的服務，我們將提供“個性化推薦”的開關設置，若您不希望我們向您推薦符合您學習需求和進度的用戶和動態內容，您可在設置中將此開關設置爲關閉。
        </p>
        <p>
          3.4
          您在使用我們産品或服務時需通過第三方關聯帳戶登錄，我們會請求第三方提供您的賬戶暱稱、頭像、賬號、性別等信息；並且，我們需要您提供手機號碼以綁定學習資格。若您拒絶提供上述信息，會導緻您無法使用我們的産品或服務。
        </p>
        <p>
          3.5
          當您在參加我們的活動，填冩用戶調查問捲，線上助教答疑等問題時，我們可能會向您收集姓名、電話、地址、教育背景、職業、工作年限等信息，接收並記錄的您的瀏覽器和計算機、移動設備等設備相關信息，這些信息包括：
          <ol>
            <li>
              設備型號，操作繫統版本，設備設置，唯一設備標識符等軟硬件特徵信息等設備信息；
            </li>
            <li>
              通過GPS，藍牙或WIFI信號接入點，藍牙和基站傳感器信息等獲得的位置信息；
            </li>
            <li>
              當您使用我們的網站或客戶端提供的産品或服務時，我們會自動收集您對我們産品或服務的詳細使用情況，作爲相關網絡日誌保存。
            </li>
          </ol>
        </p>
        <p>
          3.6 在您購買課程進行支付時，我們會收集您的
          <strong>
            第三方支付賬號（例如支付寶賬號、微信賬號、ApplePay賬號或其他形式的銀行卡信息）
          </strong>
          ，如果您開通了指紋支付或麵部識別支付，需要使用您的
          <strong>指紋或麵部信息</strong>
          進行驗証，我們不會採集您的指紋或麵部原圖，您的指紋原圖僅保存在您授權採集指紋的設備上。
        </p>
        <p>
          3.7
          注銷賬號方式：您可以在設置-關於-刪除賬號髮起注銷申請。我們會在收到您的注銷申請後，根據您的要求，刪除您的個人信息。
        </p>
        <p>
          3.8 第三方 SDK 信息收集
          <ol>
            <li>
              微信 Open SDK
              <ul>
                <li>使用目的：幫助用戶使用微信賬號登錄</li>
                <li>使用場景：用戶每次選擇微信帳號登錄時</li>
                <li>共享方式：SDK本機採集，不涉及數據共享</li>
                <li>第三方名稱：深圳市騰訊計算機繫統有限公司</li>
                <li>
                  收集/共享信息：網絡訪問，獲取Wi-Fi狀態，讀取電話狀態，冩入外部存儲，使用存儲權限讀取外置存儲信息，設備標識信息（包括IMEI、設備序列號、Android
                  ID、MAC地址、SIM卡序列號）、已安裝APP信息、傳感器信息、剪切闆。
                </li>
                <li>信息類型：操作繫統信息、設備型號信息、應用列表信息</li>
                <li>
                  隱私協議：https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8
                </li>
              </ul>
            </li>
            <li>
              阿里雲移動推送 SDK
              <ul>
                <li>使用場景：用於識別推送設備，實現向單個設備推送功能</li>
                <li>第三方名稱：阿里雲計算有限公司</li>
                <li>
                  收集信息：設備信息（包括操作繫統、設備型號），IP ，運營商信息
                </li>
                <li>
                  隱私協議：https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112071754_83380.html
                </li>
              </ul>
            </li>
            <li>
              閃驗 SDK
              <ul>
                <li>第三方主體：上海璟夢信息科技有限公司 SDK</li>
                <li>用途：爲APP用戶提供一鍵登錄、號碼驗証、安全校驗服務 SDK</li>
                <li>信息處理類型：爲了實現網關取號技術，閃驗SDK</li>
                <li>
                  需要獲取本機號碼、設備標識符（包括IDFV、OAID）、設備硬件信息（包括設備型號、設備硬件製造商）、操作繫統信息（包括操作繫統版本）、網絡信息（包括網絡類型、運營商名稱、IP地址、網絡狀態、網絡製式）、SIM卡信息（包括SIM卡狀態、subId）、應用信息（包括BundleId、包名、包籤名）。
                </li>
                <li>數據處理方式：通過去標識化、加密傳輸及其他安全方式</li>
                <li>
                  隱私協議：https://doc.chuanglan.com/document/T98GE1KYCZDMJDHN
                </li>
              </ul>
            </li>
            <li>
              中國移動
              <ul>
                <li>
                  收集信息名稱：網絡類型、網絡地址、運營商類型、本機號碼、SIM卡狀態、手機設備類型、手機操作繫統、硬件廠商
                </li>
                <li>
                  目的及其用途：識別⽤戶的⼿機號碼快速登錄/號碼校驗，問題查詢、分析、風險控製
                </li>
                <li>信息處理方式：SDK 自行採集</li>
                <li>第三方主體：中移互聯網有限公司</li>
                <li>協議名稱：《中國移動認証服務條款》</li>
                <li>
                  協議鏈接：
                  https://wap.cmpassport.com/resources/html/contract.html
                </li>
              </ul>
            </li>
            <li>
              中國聯通
              <ul>
                <li>
                  收集信息名稱：網絡類型、網絡地址、運營商類型、本機手機號、手機設備類型、手機操作繫統、硬件廠商
                </li>
                <li>目的及其用途：識別⽤戶的⼿機號碼快速登錄/號碼校驗</li>
                <li>信息處理方式：SDK 自行採集</li>
                <li>第三方主體：中國聯合網絡通信有限公司</li>
                <li>協議名稱：《中國聯通服務與隱私協議條款》</li>
                <li>協議鏈接：https://ms.zzx9.cn/html/oauth/protocol2.html</li>
              </ul>
            </li>
            <li>
              中國電信
              <ul>
                <li>
                  收集信息名稱：注冊手機號碼、本機號碼、網絡連接類型、網絡狀態信息、網絡地址、運營商類型、手機設備類型、手機設備廠商、手機操作繫統類型及版本
                </li>
                <li>
                  目的及其用途：識別⽤戶的⼿機號碼快速登錄/號碼校驗及業務風控
                </li>
                <li>信息處理方式：SDK 自行採集</li>
                <li>第三方主體：天翼數字生活科技有限公司</li>
                <li>協議名稱：《天翼服務及隱私協議》</li>
                <li>
                  協議鏈接：https://e.189.cn/sdk/agreement/detail.do?hidetop=true
                </li>
              </ul>
            </li>
            <li>
              訊飛 SDK
              <ul>
                <li>使用場景：用於語音合成</li>
                <li>第三方名稱：科大訊飛股份有限公司</li>
                <li>
                  隱私協議：https://www.xfyun.cn/doc/tts/online_tts/online_tts_sdkprivacy.html
                </li>
              </ul>
            </li>
            <li>
              小米推送SDK
              <ul>
                <li>開髮者名稱：北京小米移動軟件有限公司</li>
                <li>使用目的：用於向您推送消息</li>
                <li>
                  共享的個人信息類型：設備標識(OAID、加密的Android
                  ID)、推送消息內容、設備信息(設備廠商、型號、歸屬地、運營商名稱等)、通知欄設置
                </li>
                <li>
                  可能調用的權限：訪問網絡狀態、獲取設備信息、冩入設備存儲
                </li>
                <li>
                  隱私政策鏈接：https://dev.mi.com/console/doc/detail?pId=1822
                </li>
              </ul>
            </li>
            <li>
              OPPO推送SDK
              <ul>
                <li>第三方名稱：廣東歡太科技有限公司</li>
                <li>使用目的：用於在OPPO設備上推送消息</li>
                <li>處理方式：通過去標識化、加密傳輸和處理的安全處理方式</li>
                <li>
                  收集個人信息類型：設備標識信息、網絡信息、設備狀態信息、應用使用信息
                </li>
                <li>
                  隱私政策鏈接：
                  https://open.oppomobile.com/new/developmentDoc/info?id=10288
                </li>
              </ul>
            </li>
            <li>
              華爲推送SDK
              <ul>
                <li>第三方名稱：華爲軟件技術有限公司</li>
                <li>使用目的：用於在華爲設備上推送消息</li>
                <li>處理方式：通過去標識化、加密傳輸和處理的安全處理方式</li>
                <li>收集個人信息類型：應用標識信息、消息下髮記錄等</li>
                <li>
                  隱私政策鏈接：
                  https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177
                </li>
              </ul>
            </li>
            <li>
              魅族推送SDK
              <ul>
                <li>第三方名稱：珠海星紀魅族信息技術有限公司</li>
                <li>使用目的：用於在魅族設備上推送消息</li>
                <li>處理方式：通過去標識化、加密傳輸和處理的安全處理方式</li>
                <li>
                  收集個人信息類型：設備標識信息、位置信息、網絡狀態信息、運營商信息
                </li>
                <li>隱私政策鏈接：http://open.flyme.cn/docs?id=202</li>
              </ul>
            </li>
            <li>
              VIVO推送SDK
              <ul>
                <li>第三方名稱：維沃移動通信有限公司</li>
                <li>使用目的：用於在VIVO設備上推送消息</li>
                <li>處理方式：通過去標識化、加密傳輸和處理的安全處理方式</li>
                <li>收集個人信息類型：設備標識信息、網絡狀態信息</li>
                <li>
                  隱私政策鏈接： https://dev.vivo.com.cn/documentCenter/doc/652
                </li>
              </ul>
            </li>
            <li>
              TikTok Business SDK
              <ul>
                <li>第三方名稱：字節跳動科技有限公司</li>
                <li>使用目的：收集、分析匿名使用數據</li>
                <li>處理方式：通過去標識化、加密傳輸和處理的安全處理方式</li>
                <li>
                  收集個人信息類型：設備標識信息、網絡狀態信息、谷歌廣告
                  ID(GAID)
                </li>
                <li>
                  隱私政策鏈接：https://ads.tiktok.com/i18n/official/policy/business-products-terms
                </li>
              </ul>
            </li>
          </ol>
        </p>
        <h2>四、我們如何共享、轉讓、公開披露您的個人信息</h2>
        4.1 共享
        <p>
          我們不會向任何第三方公司，組織和個人共享您的個人信息，但以下情況除外：
          <ol>
            <li>
              獲得您或您監護人的明確同意後，我們會與其他方共享您的個人信息；
            </li>
            <li>
              我們可能會根據法律法規規定，或按政府主管部門的強製性要求，對外共享您的個人信息；
            </li>
            <li>
              爲提供、維護及改善我們的産品或服務向合作夥伴及其他第三方分享您的信息；
            </li>
            <li>
              在法律法規允許的範圍內，爲了遵守法律、維護我們及我們的關聯方或合作夥伴、您或其他用戶或社會公衆利益、財産或安全免遭損害，我們可能與其他公司和組織交換信息；
            </li>
            <li>
              我們僅會出於合法、正當、必要、特定、明確的目的共享您的個人信息，並且隻會共享提供服務所必要的個人信息。對我們與之共享個人信息的公司、組織和個人，我們會締結嚴格的保密協議，要求他們按照我們的説明，本隱私政策以及其他任何相關的保密和安全措施來處理個人信息，無權將共享的個人信息用於任何其他用途。
            </li>
          </ol>
        </p>
        4.2 轉讓
        <p>
          我們不會將您的個人信息轉讓給任何公司、組織和個人，但以下情況除外：
          <ol>
            <li>
              在獲取您或您的監護人明確同意的情況下轉移，我們會向其他方轉讓您的個人信息；
            </li>
            <li>
              在涉及合並、收購或破産清算時，如涉及到個人信息轉讓，我們會在要求新的持有您個人信息的公司、組織繼續受此隱私政策的約束，否則我們將要求該公司、組織重新向您徵求授權同意。
            </li>
          </ol>
        </p>
        4.3 公開披露
        <p>
          我們僅會在以下情況下，公開披露您的個人信息：
          <ol>
            <li>獲得您或您的監護人明確同意後；</li>
            <li>
              根據法律的披露：在法律，法律程序，訴訟或政府主管部門強製性要求的情況下，我們可能會公開披露您的個人信息。
            </li>
          </ol>
        </p>
        4.4 例外情形
        <p>
          您的知悉並同意在以下情況下，我們共享、轉讓、公開披露個人信息可無需事先徵得您的授權同意：
          <ol>
            <li>與國家安全、國防安全直接相關的；</li>
            <li>與公共安全、公共衛生、重大公共利益直接相關的；</li>
            <li>與犯罪偵查、訴訟、仲裁和判決執行等直接相關的；</li>
            <li>
              出於維護您或其他個人的生命、財産等重大合法權益但又礙難得到本人同意的；
            </li>
            <li>您自行向社會公衆公開的個人信息；</li>
            <li>
              從合法公開披露的信息中收集個人信息的，如合法的新聞報道，政府信息公開等渠道。
            </li>
          </ol>
        </p>
        <h2>五、我們如何保護您的個人信息</h2>
        <p>
          您的個人信息安全對於我們至關重要。我們將嚴格遵守相關法律法規，採取業內認可的合理可行的措施，保護您的個人信息。防止信息遭到未經授權的訪問、披露、使用、修改，避免信息損壞或丟失。
        </p>
        5.1 技術措施與數據安全措施
        <p>
          我們努力採取各種符合業界標準的物理、電子和管理方麵的安全措施來保護您的個人信息安全。我們積極建立數據分類分級製度、數據安全管理規範、數據安全開髮規範來管理規範個人信息的存儲和使用，確保未收集與我們提供的服務無關的個人信息。
        </p>
        <p>
          我們通過與信息接觸者籤署保密協議、監控和審計機製來對數據進行全麵安全控製。防止您的個人信息遭到未經授權的訪問、公開披露、使用、修改、損壞或丟失。我們已使用符合業界標準的安全防護措施保護您提供的個人、信息，防止數據遭到未經授權的訪問、公開披露、使用、修改，防止數據髮生損壞或丟失。我們會採取一切合理可行的措施，保護您的個人信息。
        </p>
        5.2 安全事件處置
        <p>
          我們將儘力確保您髮送給我們的任何信息的安全性，但請您理解，由於技術的限製以及在互聯網行業可能存在的各種噁意手段，不可能始終保証信息百分之百的安全。您需要了解，您接入我們服務所用的繫統和通訊網絡，有可能因我們可控範圍外的因素而出現問題。爲防止安全事故的髮生，我們製定了妥善的預警機製和應急預案。若不幸髮生個人信息安全事件，我們將按照法律法規的要求，及時向您告知：安全事件的基本情況和可能的影響、我們已採取或將要採取的處置措施、您可自主防範和降低風險的建議和對您的補救措施，並立即啟動應急預案，力求將損失最小化。我們將及時將事件相關情況以電話、推送通知等方式告知您，難以逐一告知用戶時，我們將會採取合理、有效的方式髮佈公告。
        </p>
        <p>
          同時，我們還將按照監管部門的相關要求，主動上報個人信息安全事件的處置情況，緊密配合政府機關的工作。
        </p>
        <h2>六、您管理個人信息的權利</h2>
        <p>我們將按照本協議所述，保証您對自己的個人信息行使以下權利：</p>
        <p>
          6.1
          訪問及更正您的個人信息，法律法規規定的特例情況除外，您有權訪問及更正您的個人信息。
        </p>
        6.2 刪除您的個人信息
        <p>
          在以下高度中，您可以向我們提出刪除個人信息的請求：
          <ol>
            <li>如果我們處理個人信息的行爲違反法律法規；</li>
            <li>如果我們收集、使用您的個人信息卻未徵得您的同意；</li>
            <li>如果我們處理個人信息的行爲違反了與您的約定；</li>
            <li>如果您不再使用我們的産品或服務，或您重新注冊了賬號；</li>
            <li>如果我們不再爲您提供産品或服務。</li>
          </ol>
          當您從我們的服務中刪除信息後，我們可能不會立即在備份繫統中刪除相應的信息，但會在備份更新時刪除這些信息。
        </p>
        6.3 響應您的請求
        <p>
          爲保障安全，您可能需要提供書麵請求，或以其他方式証明您的身份。我們可能會先要求您驗証自己的身份，然後再處理您的請求。對於無端重複，需要過多技術手段，給他人合法權益帶來的風險或者非常不切實際的請求，我們可能會拒絶。在以下情況中，按照法律法規要求，我們將無法響應您的請求：
          <ol>
            <li>與國家安全、國防安全直接相關的；</li>
            <li>與公共安全、公共衛生，重大公共利益直接相關的；</li>
            <li>與犯罪偵查、訴訟、仲裁和判決執行等直接相關的；</li>
            <li>有充分証據表明您存在主觀噁意或濫用權利的；</li>
            <li>
              響應您的請求將導緻您或其他個人，組織的合法權益受到嚴重損害的；
            </li>
            <li>涉及商業秘密的。</li>
          </ol>
        </p>
        <h2>七、我們如何使用Cookie和同類技術</h2>
        7.1. Cookie
        <p>
          爲確保網站正常運轉，我們會在您的計算機或移動設備上存儲稱爲Cookie的小數據文件。Cookie通常包含標識符、站點名稱以及一些號碼和字符、學習進度等數據。我們不會將Cookie用作本政策所述目的之外的任何用途。您可根據自己的偏好管理或刪除Cookie。您可以清除計算機上保存的所有Cookie，大部分網絡瀏覽器都提供阻止Cookie的功能。但如果您這樣做，則需要在每一次訪問我們的網站時更改用戶設置。如需詳細了解如何更改瀏覽器設置，請訪問您使用的瀏覽器的相關設置頁麵。
        </p>
        7.2 網站信標和預期標籤
        <p>
          例如，我們向您髮送的電子郵件可能包含鏈接至我們網站內容的地址鏈接。如果您單擊該鏈接，我們則會跟踪首次點擊，幫助我們了解您的産品或服務更好並改善用戶服務。網站信標通常是一種嵌入到網站或電子郵件中的透明圖像。電子郵件是否被打開。如果您不希望自己的活動以這種方式被追踪，則可以隨時從我們的寄信名單中退訂。
        </p>
        <h2>八、我們如何保護未成年人的個人信息</h2>
        <p>
          若您是18週歲以下的未成年人，在使用我們的産品或服務前應事先取得您監護人的同意，並在監護人陪同和指導下閱讀本隱私權政策。如果我們髮現在未事先獲得監護人同意的情況下收集了未滿14週歲未成年人的個人信息，我們將逐步刪除相關數據。
        </p>
        <h2>九、如何更新與修改本隱私協議</h2>
        <p>
          9.1
          本隱私協議爲《語有靈犀用戶服務協議》的重要組成部分。語有靈犀保留不時更新或修改本隱私協議的權利。
        </p>
        <p>
          9.2
          未經您明確同意，我們不會削減您按照本隱私協議所應享有的權利。我們會通過公衆號推送通知、彈窗形式等合理方式通知您，以便您能及時了解本隱私協議所做的任何變更。
        </p>
        <p>
          9.3
          若您不同意修改後的隱私協議，您有權並應立即停止使用語有靈犀的服務。如果您繼續使用語有靈犀提供的服務，則視爲您接受語有靈犀對本隱私協議相關條款所做的修改。
        </p>
        <h2>十、如何聯繫我們及糾紛解決</h2>
        <p>
          10.1
          如對本隱私協議或相關事宜有任何問題、意見或者建議，您可隨時通過客服熱線400-990-9669與語有靈犀取得聯繫。
        </p>
        <p>
          10.2
          如果您認爲我們的個人信息處理行爲損害了您的合法權益，您也可向有關政府部門進行反映。
        </p>
      </section>
    </article>
  </>
);
